import { gql, useQuery } from '@apollo/client';

export const GET_CONFIG = gql`
  query GetConfiguration {
    config {
      env
      googleMapsKey
      analytics {
        gtm {
          id
        }
      }
    }
  }
`;

export default function useConfig() {
  const { data, loading: isLoading } = useQuery(GET_CONFIG);
  const { config } = data || {};
  return [config, { isLoading }];
}
