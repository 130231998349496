import React, { Component } from 'react';
import { Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './ErrorBoundary.module.css';

const cx = classnames.bind(styles);

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    staticContext: PropTypes.shape({
      statusCode: PropTypes.number,
    }),
  };

  static defaultProps = {
    staticContext: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(e) {
    return { hasError: true, statusCode: e.status || 500 };
  }

  render() {
    const { hasError, statusCode } = this.state;
    const { children, staticContext } = this.props;

    if (staticContext && statusCode) {
      staticContext.statusCode = statusCode;
    }

    if (hasError) {
      return (
        <Container>
          <FlexGrid>
            <FlexGrid.Column className={cx('column')} tablet={6}>
              <Image alt="Generic Station Image" src="//static.gasbuddy.com/web/generic-station-us.svg" />
            </FlexGrid.Column>
            <FlexGrid.Column className={cx('column')} tablet={6}>
              <Header>
                <Text>Oops...</Text>
                <br />
                <Text>Something went wrong.</Text>
                <br />
              </Header>
              <Text as="p" color="midnight" size="lg">Looks like we&apos;re in need of a fill up!</Text>
              <Text as="p" color="grey">
                It&apos;s more likely the page you were looking for has encountered an unexpected error.
                Rest assured that our developers are working hard to repair it. Please try again later.
              </Text>
            </FlexGrid.Column>
          </FlexGrid>
        </Container>
      );
    }

    return children;
  }
}
