import { ThemeProvider } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import usePrevious from '../../../lib/hooks/usePrevious';
import { noop } from '../../../lib/utils';
import createMarkerIcon from '../../utils/createMarkerIcon';
import getMarkerColor from '../../utils/getMarkerColor';
import EmergencyStatusPropType from '../../utils/prop-types/emergency-status';
import StationInfoWindow from '../StationInfoWindow';

export default function StationMarker({
  onClick,
  onHide,
  onShow,
  open,
  station,
}) {
  const [isShowingStationInfo, setIsShowingStationInfo] = useState(open);

  const wasShowingStationInfo = usePrevious(open);

  const handleClick = useCallback((e) => {
    onClick(e);
    setIsShowingStationInfo(true);
  }, [onClick]);

  const handleClose = useCallback(() => {
    setIsShowingStationInfo(false);
  }, []);

  useEffect(() => {
    if (isShowingStationInfo !== open) {
      if (!wasShowingStationInfo) {
        onShow();
        setIsShowingStationInfo(true);
      } else {
        onHide();
        setIsShowingStationInfo(false);
      }
    }
  }, [isShowingStationInfo, wasShowingStationInfo, open, onShow, onHide]);

  const markerColor = getMarkerColor(station.emergency_status, station.fuels);

  return (
    <Marker
      optimize={false}
      icon={createMarkerIcon(markerColor)}
      position={{ lat: station.latitude, lng: station.longitude }}
      onClick={handleClick}
    >
      {isShowingStationInfo && (
        <InfoWindow
          position={{ lat: station.latitude, lng: station.longitude }}
          onCloseClick={handleClose}
        >
          <ThemeProvider theme="next">
            <StationInfoWindow
              id={station.id}
              address={station.address}
              name={station.name}
              phone={station.phone}
              fuels={station.fuels}
              emergencyStatus={station.emergency_status}
            />
          </ThemeProvider>
        </InfoWindow>
      )}
    </Marker>
  );
}

StationMarker.propTypes = {
  onClick: PropTypes.func,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  open: PropTypes.bool,
  station: PropTypes.shape({
    address: PropTypes.shape({}),
    id: PropTypes.number.isRequired,
    fuels: PropTypes.arrayOf(PropTypes.string),
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    emergency_status: EmergencyStatusPropType,
  }).isRequired,
};

StationMarker.defaultProps = {
  onClick: noop,
  onHide: noop,
  onShow: noop,
  open: false,
};
