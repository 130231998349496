import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FlexGrid, Form, Input, Loader, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import querystring from 'querystring';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation as useRRLocation } from 'react-router-dom';
import packageJson from '../../../../package.json';
import useLocation from '../../../client/hooks/useLocation';
import useStations from '../../../client/hooks/useStations';
import Legend from '../Legend';
import Navbar from '../Navbar';
import SplitScreen from '../SplitScreen';
import StationListItem from '../StationListItem';
import TrackerMap from '../TrackerMap';
import DefaultHelmet from '../DefaultHelmet';
import useFeatures, { DYNAMIC_MAP_FEATURE_ID } from '../../../client/hooks/useFeatures';
import styles from './MainPage.module.css';
import ErrorBoundary from '../ErrorBoundary';

const cx = classnames.bind(styles);

export const INITIAL_CENTER = { lat: 32.69783, lng: -80.91777 };

export default function MainPage() {
  const history = useHistory();
  const location = useRRLocation();
  const queryParams = querystring.parse((location.search || '?').substring(1));
  const [mapDragged, setMapDragged] = useState(false);
  const [activeStationId, setActiveStationId] = useState(queryParams.station_id || null);
  const [query, setQuery] = useState(queryParams.q || '');
  const [trackerMap, setTrackerMap] = useState();

  let coordinates;

  // If the user supplied a lat/lng pair in the query string, those coordinates would become the result location
  if (queryParams.lat && queryParams.lng) {
    coordinates = {
      lat: parseFloat(queryParams.lat),
      lng: parseFloat(queryParams.lng),
    };
  }

  const [features = [], { isLoading: isFetching }] = useFeatures([DYNAMIC_MAP_FEATURE_ID]);
  const [geocodedLocation, { isLoading: isGeocoding }] = useLocation(!coordinates ? queryParams.q : undefined);
  const [stations, { isLoading: isSearching }] = useStations(coordinates || geocodedLocation);
  const isLoading = isFetching || isGeocoding || isSearching;
  const activeStation = useMemo(
    () => activeStationId && stations.find(s => s.id === activeStationId),
    [activeStationId, stations],
  );
  const [center, setCenter] = useState(INITIAL_CENTER);

  const dynamicMap = features.find(f => f.name === DYNAMIC_MAP_FEATURE_ID);

  if (dynamicMap) {
    const { payload: { location: { lat, lng } } } = dynamicMap;
    coordinates = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
  }

  const handleMapLoad = useCallback((map) => {
    setTrackerMap(map);
  }, []);

  const searchMapLocation = useCallback(() => {
    const mapCenter = trackerMap.getCenter();
    const lat = mapCenter.lat();
    const lng = mapCenter.lng();

    queryParams.lat = lat;
    queryParams.lng = lng;
    delete queryParams.q;

    history.push({
      search: `?${querystring.stringify(queryParams)}`,
    });

    setActiveStationId(null);
    setMapDragged(false);
  }, [history, queryParams, trackerMap]);

  useEffect(() => {
    if (activeStation && center === INITIAL_CENTER) {
      setCenter({ lat: activeStation.latitude, lng: activeStation.longitude });
    }
  }, [activeStation, center]);

  // Attempt to use HTML5 Geolocation
  const geolocate = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.navigator.geolocation.getCurrentPosition((position) => {
        const { coords: userLocation } = position;

        if (queryParams.lat !== userLocation.latitude && queryParams.lng !== userLocation.longitude) {
          queryParams.lat = userLocation.latitude;
          queryParams.lng = userLocation.longitude;

          delete queryParams.q;

          history.push({
            search: `?${querystring.stringify(queryParams)}`,
          });

          setMapDragged(false);
        }
      }, () => {
        // TODO Handle error here
      });
    }
  }, [history, queryParams]);

  const handleMarkerShow = useCallback(({ key }) => {
    setActiveStationId(key);
  }, []);

  const handleMarkerHide = useCallback(({ key }) => {
    if (key === activeStationId) {
      setActiveStationId(null);
    }
  }, [activeStationId]);

  const stationItemClickHandlers = useMemo(() => (
    stations.map(station => () => { setActiveStationId(station.id); })
  ), [stations]);

  const handleMapDragged = useCallback(() => {
    setMapDragged(true);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    queryParams.q = query;
    delete queryParams.station_id;
    delete queryParams.lat;
    delete queryParams.lng;

    history.push({
      search: `?${querystring.stringify(queryParams)}`,
    });
  }, [history, query, queryParams]);

  const handleQueryChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  // Update query parameters if local state has changed
  useEffect(() => {
    const needsUpdate = activeStationId && queryParams.station_id !== activeStationId.toString();
    const needsRemoval = !activeStationId && queryParams.station_id;

    if (needsUpdate) {
      queryParams.station_id = activeStationId;
    } else if (needsRemoval) {
      delete queryParams.station_id;
    }

    if (needsUpdate || needsRemoval) {
      history.push({
        search: `?${querystring.stringify(queryParams)}`,
      });
    }
  }, [activeStationId, history, queryParams]);

  // Update local state if query string changes
  useEffect(() => {
    setQuery(queryParams.q || '');
  }, [queryParams.q]);

  // Update local state if query string changes
  useEffect(() => {
    setActiveStationId(queryParams.station_id ? parseInt(queryParams.station_id, 10) : null);
  }, [queryParams.station_id]);

  // Situate the map so markers are all in view appropriately
  useEffect(() => {
    if (stations.length && trackerMap) {
      const bounds = new window.google.maps.LatLngBounds();
      stations.forEach((station) => {
        bounds.extend(new window.google.maps.LatLng(station.latitude, station.longitude));
      });
      trackerMap.fitBounds(bounds);
    }
  }, [stations, trackerMap]);

  const [googleLoaded, setGoogleLoaded] = useState(typeof window !== 'undefined' && !!window.google);
  const checkForGoogle = useCallback(() => {
    if (window.google) {
      setGoogleLoaded(true);
    }
  }, []);

  // Generate markers for map
  const markers = useMemo(() => (
    stations.map(station => ({
      key: station.id,
      showInfo: station.id === activeStationId,
      station,
    }))
  ), [activeStationId, stations]);

  const supportsGeolocation = typeof window !== 'undefined' && 'geolocation' in window.navigator;

  return (
    <ErrorBoundary>
      <div>
        <DefaultHelmet onChangeClientState={checkForGoogle} />
        <Navbar>
          {mapDragged
            && (
              <Button onClick={searchMapLocation} primary className={cx('floatRight')}>
                <span className={cx('nonMobile')}>Redo Search in This Area</span>
                <span className={cx('mobile')}>Search Here</span>
              </Button>
            )
          }
        </Navbar>
        <SplitScreen style={{ top: 60 }}>
          <SplitScreen.StaticPane>
            {googleLoaded && (
              <TrackerMap
                onMapLoad={handleMapLoad}
                center={center}
                markers={markers}
                onMarkerShow={handleMarkerShow}
                onMarkerHide={handleMarkerHide}
                onDragEnd={handleMapDragged}
              />
            )}
          </SplitScreen.StaticPane>
          <SplitScreen.SlidingPane>
            <Form action="/" method="POST" onSubmit={handleSubmit} role="form">
              <Input
                innerComponent={
                  supportsGeolocation
                    ? <FontAwesomeIcon icon={faLocationArrow} className={cx('locationIcon')} onClick={geolocate} />
                    : <FontAwesomeIcon icon={faSearch} className={cx('searchIcon')} />
                }
                label="Search for stations"
                onChange={handleQueryChange}
                value={query}
                disabled={isLoading}
              />
            </Form>
            <Legend />
            <div className={cx('results')}>
              {isLoading ? (
                <Loader>
                  <Text>Loading...</Text>
                </Loader>
              ) : (
                <FlexGrid>
                  {stations.map((station, index) => (
                    <FlexGrid.Column desktop={6} key={station.id}>
                      <StationListItem
                        id={station.id}
                        address={station.address}
                        name={station.name}
                        brandName={station.brand_name}
                        distance={station.distance}
                        icon={(station.brands[0] || {}).image_url}
                        phone={station.phone}
                        onClick={stationItemClickHandlers[index]}
                        fuels={station.fuels}
                        emergencyStatus={station.emergency_status}
                      />
                    </FlexGrid.Column>
                  ))}
                </FlexGrid>
              )}
              <div className={cx(stations.length ? 'versionAndCopyrightInfo' : 'fixedVersionAndCopyrightInfo')}>
                <Text as="small">
                  v {packageJson.version} &copy; {new Date().getFullYear()} GasBuddy LLC
                </Text>
              </div>
            </div>
          </SplitScreen.SlidingPane>
        </SplitScreen>
      </div>
    </ErrorBoundary>
  );
}
