import { Image } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import * as React from 'react';
import legendItems from '../../constants/legend-items';
import createMarkerIcon from '../../utils/createMarkerIcon';
import styles from './Legend.module.css';

const cx = classnames.bind(styles);

export default function Legend() {
  return (
    <div className={cx('legend')}>
      {legendItems.map(({ color, label }) => (
        <div key={color} className={cx('legendItem')}>
          <Image
            alt="Marker icon"
            className={cx('legendItemIcon')}
            src={createMarkerIcon(color)}
          />
          &nbsp;
          {label}
        </div>
      ))}
    </div>
  );
}
