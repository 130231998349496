import MARKER_COLORS from '../constants/marker-colors';

export default function getMarkerColor(emergencyStatus, fuels = []) {
  const hasGas = emergencyStatus?.has_gas?.report_status !== false; // eslint-disable-line camelcase
  const hasPower = emergencyStatus?.has_power?.report_status !== false; // eslint-disable-line camelcase
  const hasDiesel = emergencyStatus?.has_diesel?.report_status !== false; // eslint-disable-line camelcase
  const sellsDiesel = fuels.includes('diesel');
  const ranOutOfDiesel = sellsDiesel && !hasDiesel;

  if (hasGas && hasPower && (!sellsDiesel || (sellsDiesel && hasDiesel))) {
    return MARKER_COLORS.FULL_AMENITIES;
  } if (!hasGas && !hasPower && (!sellsDiesel || ranOutOfDiesel)) {
    return MARKER_COLORS.NO_AMENITIES;
  } if (!hasGas || ranOutOfDiesel) {
    return MARKER_COLORS.LIMITED_AMENITIES;
  } if (!hasPower) {
    return MARKER_COLORS.NO_POWER;
  }

  return MARKER_COLORS.UNKNOWN;
}
