import PropTypes from 'prop-types';

export default PropTypes.shape({
  poi_id: PropTypes.number,
  update_date: PropTypes.string, // ISO format
  nick_name: PropTypes.string,
  stamp: PropTypes.string, // member, visitor, owner, data-feed
  report_type: PropTypes.string,
  report_status: PropTypes.bool,
  int_member_id: PropTypes.number,
});
