import * as React from 'react';
import PropTypes from 'prop-types';
import { Header, Text, Link } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import timeAgo from '../../utils/timeAgo';
// import UpdateAvailabilityModal from '../UpdateAvailabilityModal';
import StatusIcons from '../StatusIcons';
import EmergencyStatusPropType from '../../utils/prop-types/emergency-status';
import getLastEmergencyUpdateDate from '../../utils/getLastEmergencyUpdateDate';
import styles from './StationInfoWindow.module.css';

const cx = classnames.bind(styles);

export default function StationInfoWindow({
  id,
  name,
  address,
  phone,
  fuels,
  emergencyStatus,
}) {
  const lastUpdateTimestamp = getLastEmergencyUpdateDate(emergencyStatus);

  return (
    <div className={cx('stationInfoWindow')}>
      <Header as="h3" snug>
        <Link
          to={`//www.gasbuddy.com/station/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </Link>
      </Header>
      {!!lastUpdateTimestamp && (
        <Text as="p">
          <Text as="small">{`updated ${timeAgo(new Date(lastUpdateTimestamp))}`}</Text>
        </Text>
      )}
      <Text as="p">
        {address.line_1}
        {address.line_2 ? ` ${address.line_2}` : ''}
        {!!phone && (
          <React.Fragment>
            <br />
            <Text as="a" href={`tel:${phone}`}>{phone}</Text>
          </React.Fragment>
        )}
      </Text>
      <StatusIcons
        emergencyStatus={emergencyStatus}
        fuels={fuels}
      />
      {/* <br />
      <br />
      <UpdateAvailabilityModal
        stationId={id}
        includeDiesel={fuels.includes('diesel')}
        fluid
      /> */}
    </div>
  );
}

StationInfoWindow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({
    line_1: PropTypes.string.isRequired,
    line_2: PropTypes.string,
  }).isRequired,
  phone: PropTypes.string.isRequired,
  fuels: PropTypes.arrayOf(PropTypes.string),
  emergencyStatus: EmergencyStatusPropType,
};

StationInfoWindow.defaultProps = {
  fuels: [],
  emergencyStatus: {},
};
