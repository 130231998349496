/* eslint-disable */
function createLoadScript({ id, dataLayerName}) {
  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='//www.googletagmanager.com/gtm/js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','${dataLayerName}','${id}');
  `;
  return script;
}

function createNoScript({ id }) {
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe src="//www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
  return noscript;
}

const ReactGTM = {

  initialize({ id, dataLayerName = 'dataLayer' }) {
    window[dataLayerName] = window[dataLayerName] || [];
    document.head.appendChild(createLoadScript({ id, dataLayerName }));
    document.body.appendChild(createNoScript({ id }));
    this.dataLayerName = dataLayerName;
  },

  event() {
    if (typeof window === 'undefined') {
      return false;
    }

    window[this.dataLayerName].push(arguments);
  }

};
/* eslint-enable */

export default ReactGTM;
