import * as React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './Navbar.module.css';

const cx = classnames.bind(styles);

export default function Navbar({ children }) {
  return (
    <header className={styles.navbar}>
      <Image
        src="https://images.gasbuddy.com/images/websites/gasbuddy/gb-logo-horizontal.png"
        alt="GasBuddy logo"
        className={cx('logo')}
      />
      {children}
    </header>
  );
}

Navbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Navbar.defaultProps = {
  children: null,
};
