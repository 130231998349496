import MARKER_COLORS from './marker-colors';

export default [
  {
    label: 'Has Fuel & Power',
    color: MARKER_COLORS.FULL_AMENITIES,
  },
  {
    label: 'Has No Power',
    color: MARKER_COLORS.NO_POWER,
  },
  {
    label: 'Limited Fuel Options',
    color: MARKER_COLORS.LIMITED_AMENITIES,
  },
  {
    label: 'Has No Fuel & Power',
    color: MARKER_COLORS.NO_AMENITIES,
  },
];
