import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import measureDistance from '../../common/utils/measureDistance';

export const GET_STATIONS = gql`
  query GetStations($lat: Float!, $lng: Float!, $status: String, $openStatus: String, $limit: Int) {
    stations(lat: $lat, lng: $lng, status: $status, open_status: $openStatus, limit: $limit) {
      id
      address {
        line_1
        line_2
      }
      brand_name
      brands {
        image_url
      }
      emergency_status {
        has_gas {
          report_status
          update_date
        }
        has_power {
          report_status
          update_date
        }
        has_diesel {
          report_status
          update_date
        }
      }
      fuels
      latitude
      longitude
      name
      phone
    }
  }
`;

const withDistance = ({ lat: fromLat, lng: fromLng } = {}) => station => ({
  ...station,
  distance: measureDistance(station.latitude, station.longitude, fromLat, fromLng),
});

const byDistance = (stationA, stationB) => {
  if (stationA.distance < stationB.distance) {
    return -1;
  }
  if (stationB.distance < stationA.distance) {
    return 1;
  }
  return 0;
};

export default function useStations({ lat, lng } = {}) {
  const { data, loading: isLoading } = useQuery(GET_STATIONS, {
    skip: !lat || !lng,
    variables: { lat, lng, limit: 100 },
  });

  const stations = useMemo(() => {
    if (!(lat && lng) || !data?.stations) {
      return [];
    }

    return data?.stations
      .map(station => ({ // GraphQL casts ids as strings, so we parse as int
        ...station,
        id: parseInt(station.id, 10),
      }))
      .map(withDistance({ lat, lng }))
      .sort(byDistance);
  }, [data, lat, lng]);

  return [stations, { isLoading }];
}
