/* global google */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from '@react-google-maps/api';
import StationMarker from '../StationMarker';

const Map = ({
  markers,
  onMapLoad,
  onMarkerShow,
  onMarkerHide,
  ...rest
}) => (
  <GoogleMap onLoad={onMapLoad} {...rest}>
    {markers.map(marker => (
      <StationMarker
        key={marker.key}
        station={marker.station}
        onShow={() => onMarkerShow(marker)}
        onHide={() => onMarkerHide(marker)}
        open={marker.showInfo}
      />
    ))}
  </GoogleMap>
);

Map.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onMapLoad: PropTypes.func.isRequired,
  onMarkerShow: PropTypes.func.isRequired,
  onMarkerHide: PropTypes.func.isRequired,
};

const DEFAULT_ZOOM = 6;

export default function TrackerMap({
  __mapInstance, // This is for testing purposes only
  markers,
  onMarkerShow,
  onMarkerHide,
  ...rest
}) {
  const [map, setMap] = useState(__mapInstance);

  const fitToMarkers = useCallback(() => {
    if (!markers.length || !map) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    markers.map(marker => (
      new google.maps.Marker({
        position: {
          lat: marker.station.latitude,
          lng: marker.station.longitude,
        },
        googleMap: map,
      })
    )).forEach((marker) => {
      bounds.extend(marker.getPosition());
    });

    map.fitBounds(bounds);
  }, [markers, map]);

  // When markers are available, adjust map to fit to show all markers
  useEffect(() => {
    if (markers.length) {
      fitToMarkers();
    }
  }, [fitToMarkers, markers.length]);

  return (
    <Map
      mapContainerStyle={{ height: '100%' }}
      zoom={DEFAULT_ZOOM}
      markers={markers}
      onMapLoad={setMap}
      onMarkerShow={onMarkerShow}
      onMarkerHide={onMarkerHide}
      {...rest}
    />
  );
}

TrackerMap.propTypes = {
  __mapInstance: PropTypes.shape({}),
  markers: PropTypes.arrayOf(PropTypes.shape()),
  onMarkerShow: PropTypes.func,
  onMarkerHide: PropTypes.func,
};

TrackerMap.defaultProps = {
  __mapInstance: undefined,
  markers: [],
  onMarkerShow: () => {},
  onMarkerHide: () => {},
};
