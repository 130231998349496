import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import useConfig from '../../../client/hooks/useConfig';
import ReactGTM from '../../../lib/gtm';

/* eslint-disable max-len */
const DESCRIPTION = 'Trying to find a station with gas? Help others in your area by telling us if a station you visited is open, closed, or out of fuel.';
const THUMBNAIL = 'https://images.gasbuddy.com/images/websites/gasbuddy/apps/cellphone-app.png';
const TITLE = 'GasBuddy Gasoline Availability Tracker';

export default function DefaultHelmet(props) {
  const [config, { isLoading }] = useConfig();

  useEffect(() => {
    if (!isLoading && config?.analytics?.gtm) {
      ReactGTM.initialize(config.analytics.gtm);
    }
  }, [config, isLoading]);

  return (
    <Helmet {...props}>
      <title>{TITLE}</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" property="description" content={DESCRIPTION} />
      <meta name="og:type" property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={TITLE} />
      <meta name="og:image" property="og:image" content={THUMBNAIL} />
      <meta name="og:description" property="og:description" content={DESCRIPTION} />
      <meta name="twitter:title" property="twitter:title" content={TITLE} />
      <meta name="twitter:image" property="twitter:image" content={THUMBNAIL} />
      <meta name="twitter:description" property="twitter:description" content={DESCRIPTION} />
      <meta name="apple-itunes-app" content="app-id=406719683, app-argument=https://itunes.apple.com/us/app/gasbuddy-find-cheap-gas-prices/id406719683?mt=8&uo=4" />
      <link rel="canonical" href="https://tracker.gasbuddy.com" />
      <link rel="shortcut icon" id="favicon" type="image/x-icon" href="https://static.gasbuddy.com/web/favicon.ico" />
      <link rel="apple-touch-icon" sizes="152x152" href="https://images.gasbuddy.com/images/websites/gasbuddy/apple-touch-icon.png" />
      <link rel="alternate" href="android-app://gbis.gbandroid/gasbuddy/" />
      {!!config?.googleMapsKey && (
        <script type="text/javascript" src={`https://maps.googleapis.com/maps/api/js?v=weekly&libraries=geometry,drawing,places&key=${config.googleMapsKey}`} />
      )}
      {config?.env === 'production' && (
        <script type="text/javascript">
          {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', 'UA-3201164-84', 'auto');ga('send', 'pageview');
          `}
        </script>
      )}
    </Helmet>
  );
}
/* eslint-enable max-len */
