import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const DYNAMIC_MAP_FEATURE_ID = 'tracker_web_dynamic_map';

export const GET_FEATURES = gql`
  query GetFeatures {
    features {
      n # name
      v # value
      p # payload
    }
  }
`;

export default function useFeatures(featureIds) {
  const { data, loading: isLoading } = useQuery(GET_FEATURES);

  const features = useMemo(
    () => (data?.features || []).filter(r => featureIds.includes(r.n))
      .map(r => ({
        name: r.n,
        payload: r.p,
        value: r.v,
      })),
    [featureIds, data],
  );

  return [features, { isLoading }];
}
