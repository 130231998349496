import React, { useState, useCallback } from 'react';
import classnames from 'classnames/bind';
import { ChildrenPropType } from '../../utils/prop-types/split-screen';
import styles from './SplitScreen.module.css';

const cx = classnames.bind(styles);

function SlidingPane({
  children,
}) {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = useCallback((e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={cx('slidingPane', { open: isOpen })}>
      <button type="button" className={cx('slidingPaneToggle')} onClick={toggle}>☰</button>
      <div>{children}</div>
    </div>
  );
}

SlidingPane.propTypes = {
  children: ChildrenPropType.isRequired,
};

function StaticPane({
  children,
  ...rest
}) {
  return (
    <div className={cx('staticPane')} {...rest}>
      {children}
    </div>
  );
}

StaticPane.propTypes = {
  children: ChildrenPropType.isRequired,
};

function SplitScreen({
  children,
  ...rest
}) {
  return (
    <div className={cx('viewport')} {...rest}>
      {children}
    </div>
  );
}

SplitScreen.propTypes = {
  children: ChildrenPropType.isRequired,
};

SplitScreen.StaticPane = StaticPane;
SplitScreen.SlidingPane = SlidingPane;

export default SplitScreen;
