import * as React from 'react';
import PropTypes from 'prop-types';
import { Panel, Header, Text, Image } from '@gasbuddy/react-components';
import timeAgo from '../../utils/timeAgo';
// import UpdateAvailabilityModal from '../UpdateAvailabilityModal';
import StatusIcons from '../StatusIcons';
import EmergencyStatusPropType from '../../utils/prop-types/emergency-status';
import getLastEmergencyUpdateDate from '../../utils/getLastEmergencyUpdateDate';

import styles from './StationListItem.module.css';

export default function StationListItem({
  id, // eslint-disable-line no-unused-vars
  icon,
  name,
  brandName,
  address,
  distance,
  phone,
  fuels,
  emergencyStatus,
  ...other
}) {
  const lastUpdateTimestamp = getLastEmergencyUpdateDate(emergencyStatus);

  return (
    <Panel color="white" raised padded rounded bordered className={styles.stationListItem} {...other}>
      <Image
        src={icon}
        alt={brandName || name}
        className={styles.logo}
      />
      <Header as="h3" snug>{name}</Header>
      {!!lastUpdateTimestamp && (
        <Text as="p" size="sm">
          {`updated ${timeAgo(new Date(lastUpdateTimestamp))}`}
        </Text>
      )}
      <Text as="p">
        {address.line_1}
        {address.line_2 ? ` ${address.line_2}` : ''}
      </Text>
      <Text as="p">
        {phone
          ? (
            <React.Fragment>
              <Text as="a" href={`tel:${phone}`}>{phone}</Text>
            </React.Fragment>
          )
          : ''
        }
      </Text>
      {!lastUpdateTimestamp && (
        <br />
      )}
      <div className={styles.bottomContent}>
        <div className={styles.statusIcons}>
          <StatusIcons emergencyStatus={emergencyStatus} fuels={fuels} />
          {/* <span className={styles.updateLinkContainer}>
            <UpdateAvailabilityModal
              stationId={id}
              includeDiesel={fuels.includes('diesel')}
              link
            />
          </span> */}
        </div>
        <div className={styles.right}>
          <Text>{`${distance.toFixed(2)} mi`}</Text>
        </div>
      </div>
    </Panel>
  );
}

StationListItem.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  address: PropTypes.shape({
    line_1: PropTypes.string.isRequired,
    line_2: PropTypes.string,
  }).isRequired,
  distance: PropTypes.number.isRequired,
  phone: PropTypes.string,
  fuels: PropTypes.arrayOf(PropTypes.string),
  emergencyStatus: EmergencyStatusPropType,
};

StationListItem.defaultProps = {
  icon: 'https://images.gasbuddy.com/b/1/0/wp/logo.png',
  brandName: null,
  phone: null,
  fuels: [],
  emergencyStatus: {},
};
