import { useEffect, useState } from 'react';

export default function useLocation(address) {
  const [location, setLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const geocoder = window.google && new window.google.maps.Geocoder();
    if (geocoder && address) {
      setIsLoading(true);

      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const { location: result } = results[0].geometry;

          const lat = result.lat();
          const lng = result.lng();

          setLocation({ lat, lng });
        }

        setIsLoading(false);
      });
    } else {
      setLocation(undefined);
    }
  }, [address]);

  return [location, { isLoading }];
}
