export default function getLastEmergencyUpdateDate(emergencyStatus) {
  const types = ['has_gas', 'has_power', 'has_diesel'];

  return types.map(type => emergencyStatus?.[type]?.update_date) // eslint-disable-line camelcase
    .filter(updateDate => !!updateDate)
    .reduce((mostRecent, current) => {
      if (!mostRecent) {
        return current;
      }

      return Date.parse(mostRecent) < Date.parse(current) ? current : mostRecent;
    }, null);
}
