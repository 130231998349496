import * as React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@gasbuddy/react-components';
import EmergencyStatusPropType from '../../utils/prop-types/emergency-status';
import styles from './StatusIcons.module.css';

export default function StatusIcons({ emergencyStatus, fuels }) {
  const hasGas = emergencyStatus?.has_gas?.report_status !== false; // eslint-disable-line camelcase
  const hasPower = emergencyStatus?.has_power?.report_status !== false; // eslint-disable-line camelcase
  const hasDiesel = emergencyStatus?.has_diesel?.report_status !== false; // eslint-disable-line camelcase
  const sellsDiesel = fuels.includes('diesel');

  return (
    <span>
      <Image
        src={hasGas
          ? 'https://static.gasbuddy.com/web/tracker/Fuel.svg'
          : 'https://static.gasbuddy.com/web/tracker/NoFuel.svg'
        }
        className={styles.statusIcon}
        alt={hasGas ? 'Has Gas' : 'Has No Gas'}
      />
      {sellsDiesel && (
        <Image
          src={hasDiesel
            ? 'https://static.gasbuddy.com/web/tracker/HasDiesel3.svg'
            : 'https://static.gasbuddy.com/web/tracker/HasNoDiesel3.svg'
          }
          className={styles.statusIcon}
          alt={hasDiesel ? 'Has Diesel' : 'Has No Diesel'}
        />
      )}
      <Image
        src={hasPower
          ? 'https://static.gasbuddy.com/web/tracker/Power.svg'
          : 'https://static.gasbuddy.com/web/tracker/NoPower.svg'
        }
        className={styles.statusIcon}
        alt={hasPower ? 'Has Power' : 'Has No Power'}
      />
    </span>
  );
}

StatusIcons.propTypes = {
  emergencyStatus: EmergencyStatusPropType,
  fuels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

StatusIcons.defaultProps = {
  emergencyStatus: {},
};
